function AgendaGridView(element, calendar, viewName) {
	var t = this;

	// exports
	t.renderAgenda = renderAgenda;
	t.setHeight = setHeight;
	t.setWidth = setWidth;
	t.renderDayOverlay = renderDayOverlay;
	t.defaultSelectionEnd = defaultSelectionEnd;
	t.renderSelection = renderSelection;
	t.clearSelection = clearSelection;
	t.reportDayClick = reportDayClick; // for selection (kinda hacky)
	t.dragStart = dragStart;
	t.dragStop = dragStop;
	t.getHoverListener = function () {
		return hoverListener;
	};
	t.colLeft = colLeft;
	t.colRight = colRight;
	t.colContentLeft = colContentLeft;
	t.colContentRight = colContentRight;
	t.getIsCellAllDay = function () {
		return true;
	};
	t.allDayRow = allDayRow;
	t.getRowCnt = function () {
		return rowCnt;
	};
	t.getColCnt = function () {
		return colCnt;
	};
	t.getColWidth = function () {
		return colWidth;
	};
	t.getDaySegmentContainer = function () {
		return daySegmentContainer;
	};

	t.getResources = calendar.fetchResources;
	t.setTimeline = setTimeline;

	// imports
	View.call(t, element, calendar, viewName);
	OverlayManager.call(t);
	SelectionManager.call(t);

	GridEventRenderer.call(t);
	var opt = t.opt;
	var trigger = t.trigger;
	var renderOverlay = t.renderOverlay;
	var clearOverlays = t.clearOverlays;
	var daySelectionMousedown = t.daySelectionMousedown;
	var cellToDate = t.cellToDate;
	var cellToTime = t.cellToTime;
	var dateToCell = t.dateToCell;
	var createExternalDragTimeDates = t.createExternalDragTimeDates;
	var resourceAgendaGridRangeToSegments = t.resourceAgendaGridRangeToSegments;
	var formatDate = calendar.formatDate;
	var calculateWeekNumber = calendar.calculateWeekNumber;

	// locals

	var table;
	var head;
	var headCells;
	var body;
	var bodyRows;
	var bodyCells;
	var bodyFirstCells;
	var bodyPosition;
	var firstRowCellInners;
	var firstRowCellContentInners;
	var daySegmentContainer;

	var viewWidth;
	var viewHeight;
	var colWidth;
	var weekNumberWidth;

	var rowCnt, colCnt;
	var showNumbers;
	var coordinateGrid;
	var hoverListener;
	var colPositions;
	var colContentPositions;

	var tm;
	var colFormat;
	var showWeekNumbers;

	var minTime;
	var maxTime;
	var gridStartTime;
	var slotDuration;

	var gridTimes;

	var manageClone = t.manageClone;

	//Customized for seedcode
	var breakoutHeader = opt('breakoutHeader');
	var breakoutHead;
	var breakoutHeadCells;

	//Customized for seedcode
	var resourceGrid;
	var showRowLabels;
	var rowLabelWidth;
	var resources;

	var timeline;
	var timelineTime;

	//Customized for seedcode - Add class to specify scroll area of calendar
	element.parent().addClass('calendar-scroll touch-scroll');

	/* Rendering
	------------------------------------------------------------*/
	element.addClass('fc-grid');
	//disableTextSelection(element.addClass('fc-grid'));

	function generateAgendaAxisFormat(options, langData) {
		return langData
			.longDateFormat('LT')
			.replace(':mm', '(:mm)')
			.replace(/(\Wmm)$/, '($1)') // like above, but for foreign langs
			.replace(/\s*a$/i, 'a'); // convert AM/PM/am/pm to lowercase. remove any spaces beforehand
	}

	function generateAgendaTimeFormat(options, langData) {
		return langData.longDateFormat('LT').replace(/\s*a$/i, ''); // remove trailing AM/PM
	}

	function renderAgenda(_rowCnt, _colCnt, _showNumbers) {
		//Show horizontal row labels for resources - Customized for seedcode
		gridTimes = opt('gridTimes');
		resources = t.getResources(false);

		showRowLabels = true;
		resourceGrid = true;

		minTime = moment.duration(opt('minTime'));
		maxTime = moment.duration(opt('maxTime'));
		gridStartTime = moment.duration(opt('gridStartTime'));
		slotDuration = moment.duration(opt('slotDuration'));

		rowCnt = _rowCnt;
		colCnt = _colCnt;
		showNumbers = _showNumbers;
		updateOptions();

		if (!body) {
			buildEventContainer();
		}
		buildTable();
		if (!timeline) {
			timeline = "<div id='timelineContainerVert'>" + '</div>';

			$('.fc-view').append(timeline);
		}

		//Enable tooltips for this view //Customized for SeedCode
		calendar.generateBreakoutTooltips(
			'right',
			$(element),
			resources,
			false
		);
	}

	function updateOptions() {
		tm = opt('theme') ? 'ui' : 'fc';
		colFormat = getFormatFromMatrix(
			opt('columnFormat'),
			(calendar.getContentWidth() - 100) / colCnt
		);
		showWeekNumbers =
			(opt('weekNumbers') && t.name !== 'basicWeek') ||
			(opt('weekNumbers') &&
				opt('titleFormat').toLowerCase() !== 'w' &&
				t.name === 'basicWeek');
	}

	function buildEventContainer() {
		daySegmentContainer = $(
			"<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>"
		).appendTo(element);
	}

	function buildTable() {
		var html = buildTableHTML();

		if (table) {
			table.remove();
		}
		table = $(html).appendTo(element);

		head = table.find('thead.fc-fixed-head');
		breakoutHead = $('.fc-breakout-header');
		headCells = head.find('.fc-day-header');
		breakoutHeadCells = breakoutHead.find('.fc-day-header');
		body = table.find('tbody');
		bodyRows = body.find('tr');
		bodyCells = body.find('.fc-day');
		dayNumbers = $('.fc-day').find('.fc-day-number'); //Customized for seedcode
		bodyFirstCells = bodyRows.find('td:first-child');

		firstRowCellInners = bodyRows.eq(0).find('.fc-day > div');
		firstRowCellContentInners = bodyRows
			.eq(0)
			.find('.fc-day-content > div');

		markFirstLast(head.add(head.find('tr'))); // marks first+last tr/th's
		markFirstLast(bodyRows); // marks first+last td's
		bodyRows.eq(0).addClass('fc-first');
		bodyRows.filter(':last').addClass('fc-last');

		bodyCells.each(function (i, _cell) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			trigger('dayRender', t, date, $(_cell));
		});
		dayBind(bodyCells);

		//Bind day numbers - Customized for seedcode

		dayNumbers.each(function (i, dayNumber) {
			var date = cellToDate(Math.floor(i / colCnt), i % colCnt);
			$(dayNumber).data({date: date});
		});
		dayNumberBind(dayNumbers);

		var content = breakoutHead;
		if (!timelineTime) {
			timelineTime = $('.fc-header').before(
				"<div id='timelineTimeVertContainer'></div>"
			);
		}
		$('.fc-header').before(content);

		$('.fc-content').css(
			'top',
			breakoutHead.outerHeight() - head.outerHeight() + 'px'
		);
	}

	/* HTML Building
	-----------------------------------------------------------*/

	function buildTableHTML() {
		//Customized for seedcode to split the header and content. This way we can make the content scrollable independent from the head
		var additionalHead = '';
		if (breakoutHeader) {
			additionalHead =
				"<table class='fc-border-separate fc-breakout-header dbk_columnHeader' style='width: 100%;' cellspacing='0'>" +
				buildBreakoutHeadHTML(true, false) +
				'</table>';
		}

		var html =
			additionalHead +
			"<table class='fc-border-separate' style='width:100%' cellspacing='0'>" +
			buildHeadHTML(false, breakoutHeader) +
			buildBodyHTML() +
			'</table>';

		return html;
	}

	function buildHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		// var borderStyle = hideLabels ? 'border:none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? '' : ''; //Customized for seedcode

		var slotTime;
		var slotDate;
		var minutes;
		var slotNormal =
			slotDuration.asMinutes() % slotDuration.asMinutes() === 0;

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		if (showWeekNumbers) {
			html +=
				"<th class='fc-week-number " +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				htmlEscape(opt('weekNumberTitle')) +
				'</span>' +
				'</th>';
		}
		//customized for seedcode add vertical resource header
		html +=
			"<th class='fc-row-label fc-row-label-header " +
			headerClass +
			"' style='" +
			borderStyle +
			"'>" +
			"<span style='" +
			hideStyle +
			"'>" +
			htmlEscape(opt('resourceText')) +
			'</span>' +
			'</th>';
		date = cellToDate(0, 0);
		for (var i = 0; i < gridTimes.length; i++) {
			slotDate = calendar.unzoneDate(t.start).time(gridTimes[i]); // will be in UTC but that's good. to avoid DST issues
			minutes = slotDate.minutes();
			html +=
				"<th class='fc-day-header fc-time-header fc-" +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				(!slotNormal || !minutes
					? htmlEscape(formatDate(slotDate, opt('axisFormat')))
					: '<span class="minutes">' + minutes + '</span>') + //This is customized to show minuts. Was "&nbsp;"
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBreakoutHeadHTML(isBreakout, hideLabels) {
		var headerClass = tm + '-widget-header';
		var html = '';
		var col;
		var date;
		var designationClass = isBreakout ? 'breakout' : 'fixed-head'; //Customized for seedcode
		var hideStyle = hideLabels ? 'display: none;' : ''; //Customized for seedcode
		var borderStyle = hideLabels ? 'border: none;' : ''; //Customized for seedcode

		var slotTime;
		var slotDate;
		var minutes;
		var slotNormal =
			slotDuration.asMinutes() % slotDuration.asMinutes() === 0;

		html +=
			"<thead class='fc-" + designationClass + " dbk_columnHeader'><tr>"; //Customized for seedcode

		if (showWeekNumbers) {
			html +=
				"<th class='fc-week-number " +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				htmlEscape(opt('weekNumberTitle')) +
				'</span>' +
				'</th>';
		}
		//customized for seedcode add vertical resource header
		html +=
			"<th class='fc-row-label fc-row-label-header " +
			headerClass +
			"' style='" +
			borderStyle +
			"'>" +
			"<span style='" +
			hideStyle +
			"'>" +
			htmlEscape(opt('resourceText')) +
			'</span>' +
			'</th>';
		date = cellToDate(0, 0);
		for (var i = 0; i < gridTimes.length; i++) {
			slotDate = calendar.unzoneDate(t.start).time(gridTimes[i]); // will be in UTC but that's good. to avoid DST issues
			minutes = slotDate.minutes();
			html +=
				"<th class='fc-day-header fc-time-header fc-" +
				dayIDs[date.day()] +
				' ' +
				'dbk_day_' +
				dayIDs[date.day()] +
				' ' +
				headerClass +
				"' style='" +
				borderStyle +
				"'>" +
				"<span style='" +
				hideStyle +
				"'>" +
				(!slotNormal || !minutes
					? htmlEscape(formatDate(slotDate, opt('axisFormat')))
					: '<span class="minutes">' + minutes + '</span>') + //This is customized to show minuts. Was "&nbsp;"
				'</th>';
		}

		html += '</tr></thead>';

		return html;
	}

	function buildBodyHTML() {
		var contentClass = tm + '-widget-content dbk_cellContent';
		var html = '';
		var row;
		var col;
		var date;
		var weekNumberContent;
		var weekNumberBorderStyle;
		var resource;

		var slotTime;
		var slotDate;
		var minutes;
		var slotNormal =
			slotDuration.asMinutes() % slotDuration.asMinutes() === 0;
		var resourceClass;

		html += "<tbody class='dbk_calendarContent'>";

		for (row = 0; row < rowCnt; row++) {
			resource = resources[row];

			html += "<tr class='fc-week'>";

			if (showWeekNumbers) {
				date = cellToDate(0, 0);
				weekNumberContent =
					row === 0
						? htmlEscape(calculateWeekNumber(date, colCnt))
						: '';
				weekNumberBorderStyle =
					row !== 0 ? 'border-top-color: transparent;' : '';
				html +=
					"<td class='fc-week-number " +
					contentClass +
					"' style='" +
					weekNumberBorderStyle +
					"'>" +
					'<div>' +
					weekNumberContent +
					'</div>' +
					'</td>';
			}
			//Customized for seedcode to add resources
			resourceClass = resource.class ? ' ' + resource.class : '';

			html +=
				"<td class='fc-row-label fc-row-label-content " +
				contentClass +
				resourceClass +
				"'>" +
				"<div class='row-head-content'>" +
				'<div>' +
				"<div class='name'>" +
				"<span data-toggle='tooltip' data-position='" +
				row +
				"'>" +
				resource.name +
				'</span>' +
				'</div>' +
				(resource.description
					? "<div class='description'>" +
						resource.description +
						'</div>'
					: '') +
				(resource.dynamicContent
					? "<div class='dynamic-content'>" +
						resource.dynamicContent +
						'</div>'
					: '') +
				'</div>' +
				'</div>' +
				'</td>';

			date = cellToDate(0, 0);

			for (var i = 0; i < gridTimes.length; i++) {
				slotDate = t.start.clone().time(gridTimes[i]); // will be in UTC but that's good. to avoid DST issues
				minutes = slotDate.minutes();
				date = cellToDate(0, col);
				html += buildCellHTML(slotDate, resource, row);
			}

			html += '</tr>';
		}

		html += '</tbody>';

		return html;
	}

	function buildCellHTML(date, resource, breakoutPosition) {
		// date assumed to have stripped time
		var month = t.intervalStart.month();
		var today = calendar.getNow().stripTime();
		var cell = dateToCell(date); //Customized for seedcode so the day number can reference a specific cell. Used to insert more events label.
		var html = '';
		var contentClass = tm + '-widget-content dbk_cellContent';
		var classNames = [
			'fc-day',
			'fc-' + dayIDs[date.day()],
			'dbk_day_' + dayIDs[date.day()],
			contentClass,
		];

		if (date.month() != month) {
			classNames.push('fc-other-month');
		}
		if (date.isSame(today, 'day')) {
			classNames.push(
				'fc-today',
				tm + '-state-highlight',
				'dbk_day_today'
			);
		} else if (date < today) {
			classNames.push('fc-past');
		} else {
			classNames.push('fc-future');
		}

		html +=
			'<td' +
			" class='" +
			classNames.join(' ') +
			"'" +
			" data-date='" +
			date.format() +
			"'" +
			" data-resource='" +
			resource.nameSafe +
			"'" +
			" data-breakout-position='" +
			breakoutPosition +
			"'" +
			'>' +
			'<div>';

		//Customized for seedcode for show more and added class to target clicks for show day on click
		if (showNumbers) {
			html +=
				"<div class='dbk_dayNumberHeader'><div id='fc-cell-" +
				cell.row +
				'-' +
				cell.col +
				"' class='fc-day-number'>" +
				"<span class='more-events'></span>" +
				date.date() +
				'</div></div>'; //Customized for seedcode to contain cell reference
		}

		html +=
			"<div class='fc-day-content'>" +
			"<div style='position:relative'>&nbsp;</div>" +
			'</div>' +
			'</div>' +
			'</td>';
		return html;
	}

	/* Dimensions
	-----------------------------------------------------------*/

	function setHeight(height) {
		viewHeight = height;
		var headHeight = breakoutHeader
			? breakoutHead.outerHeight()
			: head.height(); //Customized for seedcode
		var bodyHeight = Math.max(viewHeight - headHeight, 0); //Customized for seedcode
		var rowHeight;
		var rowHeightLast;
		var cell;
		var rows = resources.length;

		if (opt('weekMode') == 'variable') {
			rowHeight = rowHeightLast = Math.floor(
				bodyHeight / (rows == 1 ? 2 : 6)
			);
		} else {
			rowHeight = Math.floor(bodyHeight / rows);
			rowHeightLast = bodyHeight - rowHeight * (rows - 1);
		}

		bodyFirstCells.each(function (i, _cell) {
			if (i < rows) {
				cell = $(_cell);
				cell.find('> div').css(
					'min-height',
					(i == rows - 1 ? rowHeightLast : rowHeight) - vsides(cell)
				);
			}
		});
	}

	function setWidth(width) {
		viewWidth = width;
		colPositions.clear();
		colContentPositions.clear();

		weekNumberWidth = 0;
		rowLabelWidth = 0;
		if (showWeekNumbers) {
			weekNumberWidth = head.find('th.fc-week-number').outerWidth();
			weekNumberWidth = breakoutHead
				.find('th.fc-week-number')
				.outerWidth();
		}
		if (showRowLabels) {
			rowLabelWidth = head.find('th.fc-row-label').outerWidth();
		}

		colWidth = Math.floor(
			(viewWidth - weekNumberWidth - rowLabelWidth) / colCnt
		);
		setOuterWidth(headCells.slice(0, -1), colWidth);
		setOuterWidth(breakoutHeadCells.slice(0, -1), colWidth);

		//Customized for SeedCode. Apply column width to label in last column so a long label doesn't push content. Remove 2 at the end for a bit of breathing room.
		var lastColWidth =
			viewWidth -
			weekNumberWidth -
			rowLabelWidth -
			colWidth * (colCnt - 1) -
			2;
		setOuterWidth(headCells.slice(-1).find('span'), lastColWidth);
		setOuterWidth(breakoutHeadCells.slice(-1).find('span'), lastColWidth);

		bodyPosition = body[0].getBoundingClientRect();

		//Initialize the timeline after the horizontal size has been set
		setTimeline();
	}

	/* Day clicking and binding
	-----------------------------------------------------------*/

	//Customized for seedcode to allow clicking on day numbers
	function dayNumberBind(dayNumbers) {
		dayNumbers.click(dayNumberClick);
	}

	function dayNumberClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayNumberClick', this, date, ev);
		}
	}

	function dayBind(days) {
		days.click(dayClick).mousedown(daySelectionMousedown);
		days.dblclick(dayDblClick);
		days.addTouch(true);
	}

	function longTouch(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			dayDblClick(ev.originalEvent.touches[0]);
		}
	}

	function dayClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			trigger('dayClick', this, date, ev);
		}
	}

	//Customized for seedcode for double click
	function dayDblClick(ev) {
		if (!opt('selectable')) {
			// if selectable, SelectionManager will worry about dayClick
			var date = calendar.moment($(this).data('date'));
			var breakoutPosition = $(this).data('breakout-position');
			var resource = resources[breakoutPosition]
				? resources[breakoutPosition]
				: null;
			ev.resource = resource;
			trigger('dayDblClick', this, date, ev);
		}
	}

	/* Semi-transparent Overlay Helpers
	------------------------------------------------------*/
	// TODO: should be consolidated with AgendaView's methods

	function renderDayOverlay(
		overlayStart,
		overlayEnd,
		refreshCoordinateGrid,
		resource
	) {
		// overlayEnd is exclusive

		if (refreshCoordinateGrid) {
			coordinateGrid.build();
		}

		var segments = resourceAgendaGridRangeToSegments(
			overlayStart,
			overlayEnd,
			false,
			resource
		);

		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			dayBind(
				renderCellOverlay(
					segment.row,
					segment.leftCol,
					segment.row,
					segment.rightCol
				)
			);
		}
	}

	function renderCellOverlay(row0, col0, row1, col1) {
		// row1,col1 is inclusive
		var rect = coordinateGrid.rect(row0, col0, row1, col1, element);
		return renderOverlay(rect, element);
	}

	/* Selection
	-----------------------------------------------------------------------*/

	function defaultSelectionEnd(start) {
		return start.clone().stripTime().add(1, 'days');
	}

	function renderSelection(start, end) {
		// end is exclusive
		renderDayOverlay(start, end, true); // true = rebuild every time
	}

	function clearSelection() {
		clearOverlays();
	}

	function reportDayClick(date, ev) {
		var cell = dateToCell(date);
		var _element = bodyCells[cell.row * colCnt + cell.col];
		trigger('dayClick', _element, date, ev);
	}

	/* External Dragging
	-----------------------------------------------------------------------*/

	function dragStart(_dragElement, ev, ui) {
		hoverListener.start(function (cell) {
			const draggedEvent = ui?.helper?.data;

			clearOverlays();
			if (cell) {
				const dateCell = {row: 0, col: cell.col};
				const newStart = cellToTime(dateCell);
				const resource = t.getResources(false)[cell.row];
				const dates = createExternalDragTimeDates(
					newStart,
					draggedEvent
				);
				renderDayOverlay(dates.start, dates.end, false, [
					resource.name,
				]);
			}
		}, ev);
	}

	function dragStop(_dragElement, ev, ui) {
		const cell = hoverListener.stop();
		const draggedEvent = ui?.helper?.data;
		const isClone = manageClone(ev, ui, draggedEvent);

		clearOverlays();

		if (cell) {
			const dateCell = {row: 0, col: cell.col};
			const resource = t.getResources(false)[cell.row];
			const newStart = cellToTime(dateCell);
			const dates = createExternalDragTimeDates(newStart, draggedEvent);
			trigger(
				'drop',
				_dragElement,
				dates.start,
				dates.end,
				ev,
				ui,
				{
					value: resource,
					field: 'resource',
				},
				isClone
			);
		}
	}

	/* Utilities
	--------------------------------------------------------*/

	coordinateGrid = new CoordinateGrid(function (rows, cols) {
		var e, n, p;
		headCells.each(function (i, _e) {
			e = $(_e);
			n = e.offset().left;
			if (i) {
				p[1] = n;
			}
			p = [n];
			cols[i] = p;
		});
		p[1] = n + e.outerWidth();
		bodyRows.each(function (i, _e) {
			if (i < rowCnt) {
				e = $(_e);
				n = e.offset().top;
				if (i) {
					p[1] = n;
				}
				p = [n];
				rows[i] = p;
			}
		});
		p[1] = n + e.outerHeight();
	});

	hoverListener = new HoverListener(coordinateGrid);

	colPositions = new HorizontalPositionCache(function (col) {
		return {bodyPosition: bodyPosition, cell: firstRowCellInners.eq(col)};
	});

	colContentPositions = new HorizontalPositionCache(function (col) {
		return {
			bodyPosition: bodyPosition,
			cell: firstRowCellContentInners.eq(col),
		};
	});

	function colLeft(col) {
		return colPositions.left(col);
	}

	function colRight(col) {
		return colPositions.right(col);
	}

	function colContentLeft(col) {
		return colContentPositions.left(col);
	}

	function colContentRight(col) {
		return colContentPositions.right(col);
	}

	function allDayRow(i) {
		return bodyRows.eq(i);
	}

	function setTimeline() {
		var now = fc.createTimezoneTime(moment());
		var timeArr = now.format('HH:mm:ss').split(':');
		var timeInMilliseconds = timeArr[0] * 3600000 + timeArr[1] * 60000;
		var standardTime = now.format(opt('timeFormat'));
		var styleLeft;
		var millisecondsOff;
		var timelineTimeoutID;
		var timelineContainer = $('#timelineContainerVert');
		var timelineTextContainer = $('#timelineTimeVertContainer');
		var focusTime = Number(moment.duration(timeInMilliseconds));
		var gridFirst = Number(gridTimes[0]);
		var gridLast = Number(gridTimes[gridTimes.length - 1]);
		var increment = Number(gridTimes[1] - gridFirst);

		if (
			now.isBefore(t.start) ||
			now.isAfter(t.end) ||
			focusTime < gridFirst ||
			focusTime > gridLast + increment
		) {
			timelineContainer.css('display', 'none');
			timelineTextContainer.css('display', 'none');
		} else {
			timelineContainer.css('display', 'block');
			timelineTextContainer.css('display', 'block');
			// Style timeline top height
			styleLeft = computeTimeLeft(moment.duration(timeInMilliseconds))
				.toFixed(2)
				.toString();

			timelineContainer.css('left', styleLeft + 'px');

			// Change text of timeline to current time
			timelineTextContainer
				.html("<span id='timelineTimeVert'>" + standardTime + '</span>')
				.css('left', styleLeft + 'px');
		}

		// if millisecondsOff greater than 2000 milliseconds setTimeout at most 2.1 second behind real time
		// else setTimeout at most 2.1 seconds behind real time
		millisecondsOff = (60 - timeArr[2]) * 1000;

		//Clear any existing timeouts
		window.clearTimeout(t.calendar.timelineTimeoutID);
		//Set new timeouts
		if (millisecondsOff >= 2000) {
			t.calendar.timelineTimeoutID = window.setTimeout(
				setTimeline,
				millisecondsOff + 2100
			);
		} else {
			t.calendar.timelineTimeoutID = window.setTimeout(
				setTimeline,
				62100
			);
		}
	}
	function computeTimeLeft(time) {
		// time is a duration
		var timeNum = Number(time);
		var gridNum;
		var leftCol;
		var slotDuration = moment.duration(opt('slotDuration'));
		var remainder;

		var i = 0;
		gridNum = Number(gridTimes[i]);

		while (gridNum <= timeNum && i < gridTimes.length) {
			i++;
			gridNum = Number(gridTimes[i]);
		}
		leftCol = i - 1;
		remainder = (timeNum - Number(gridTimes[leftCol])) / slotDuration;
		return colLeft(leftCol) + colWidth * remainder;
	}
}
